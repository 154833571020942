import Vue from 'vue'
import Vuex from 'vuex'
import resource from '../api/resource'

const expertsApi = new resource('experts')
const serviceApi = new resource('products')
const eventsApi = new resource('events')

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        isLoading: false,
        loader:{
            fullPage: true,
            heightLoader: 96,
            widthLoader: 96,
            loader: 'bars',
            bcolor: '#ffffff',
            fcolor: '#c32496',
        },
        experts: [],
        services: [],
        events: [],
        compName: 'start',
        requestData: {
            data: {
                expert_id: false,
                product_id: false,
                event_id: false,
                price: false,
            },
            user: {
                last_name: false,
                first_name: false,
                middle_name: false,
                birthday: false,
                city: false,
                email: false,
                phone: false,
                isRecording: false,
                date_recording: false,
            },
        },
    },
    getters: {
        events: state => {
            let eventList = []
            let expertEvent = state.events.filter(item => {
                return item.expert.id === state.requestData.data.expert_id
            })
            expertEvent.forEach(element => {
                let parseDate = element.date_time.date.split('.')
                let dateStartMonth = new Date(parseDate[2], parseDate[1], '01').getTime()
                let dateNow = new Date().getTime()
                if(dateStartMonth > dateNow && !element.status){
                    eventList.push({
                        id: element.id,
                        title: element.date_time.time,
                        data: parseDate[2] + '-' + parseDate[1] + '-' + parseDate[0],
                        status: element.status === 0,
                    })
                }
            })
            return eventList
        },
    },
    mutations: {
        SET_EXPERTS: (state, experts) => {
            state.experts = experts
        },
        SET_SERVICES: (state, services) => {
            state.services = services
        },
        SET_EVENTS: (state, events) => {
            state.events = events
        },
        SET_COMP: (state, compName) => {
            state.compName = compName
        },
        SET_REQUEST: (state, requestData) => {
            state.requestData = requestData
        },
        set(state, {type, items}) {
            state[type] = items
        }
    },
    actions: {
        setLoader({commit}, params) {
            commit('set', {type: 'isLoading', items: params});
        },
        setExperts({commit}) {
            return new Promise((resolve, reject) => {
                expertsApi.getExperts({'filter': '11'})
                    .then(response => {
                        const { data } = response

                        if (!data) {
                            reject('Verification failed, please Login again.')
                        }

                        commit('SET_EXPERTS', data)
                        resolve(data)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },
        setServices({commit}) {
            return new Promise((resolve, reject) => {
                serviceApi.list({'filter': '11'})
                    .then(response => {
                        const {data} = response

                        if (!data) {
                            reject('Verification failed, please Login again.')
                        }

                        commit('SET_SERVICES', data)
                        resolve(data)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },
        setEvents({commit}) {
            return new Promise((resolve, reject) => {
                eventsApi.getEventList({})
                    .then(response => {
                        const {data} = response

                        if (!data) {
                            reject('Verification failed, please Login again.')
                        }

                        commit('SET_EVENTS', data)
                        resolve(data)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },
        setComp({commit}, data) {
            commit('SET_COMP', data)
        },
        setRequestData({commit}, data) {
            commit('SET_REQUEST', data)
        },
    },
})
