import Vue from 'vue'
import App from './App.vue'
import store from './store'

// Import component
import Loading from 'vue-loading-overlay'
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css'

const VueInputMask = require('vue-inputmask').default

Vue.component('loading', Loading)
Vue.use(VueInputMask)
Vue.config.productionTip = false

new Vue({
  store,
  render: h => h(App)
}).$mount('#app')
