<template>
  <div class="recording">
    <div class="recording--field" :class="[postForm.first_name.length > 0 ? '' : 'error']">
      <input type="text" id="first_name" name="first_name" placeholder="Имя..." v-model="postForm.first_name">
    </div>
    <div class="recording--field" :class="[postForm.last_name.length > 0 ? '' : 'error']">
      <input type="text" id="last_name" name="last_name" placeholder="Фамилия..." v-model="postForm.last_name">
    </div>
    <div class="recording--field">
      <input type="text" id="middle_name" name="middle_name" placeholder="Отчество..." v-model="postForm.middle_name">
    </div>
    <div class="recording--field birthday" :class="[postForm.birthday.length > 0 ? '' : 'error']">
      <label for="birthday">Дата рождения:</label>
      <input type="text" id="birthday" name="birthday" v-mask="'99-99-9999'" v-model="postForm.birthday"/>
    </div>
    <div class="recording--field" :class="[postForm.city.length > 0 ? '' : 'error']">
      <input type="text" id="city" name="city" placeholder="Город проживания..." v-model="postForm.city">
    </div>
    <div class="recording--field" :class="[postForm.phone.length > 0 ? '' : 'error']">
      <input type="tel" id="phone" name="phone" placeholder="Телефон..." v-model="postForm.phone">
    </div>
    <div class="recording--field" :class="[postForm.email.length > 0 ? '' : 'error']">
      <input type="text" id="email" name="email" placeholder="E-mail..." v-model="postForm.email">
    </div>
    <div class="recording__action">
      <div class="recording__btn" @click="sendOrder()" :disabled="!validFields" :class="[validFields ? '' : 'no-active']">Записаться</div>
    </div>
  </div>
</template>

<script>

import resource from '../api/resource'

const ordersApi = new resource('orders')

const requestData = {
  data: {
    expert_id: false,
    product_id: false,
    price: false,
  },
  user: {
    last_name: false,
    first_name: false,
    middle_name: false,
    birthday: false,
    city: false,
    email: false,
    phone: false,
    isRecording: false,
    date_recording: false,
  },
}

export default {
  name: "LayoutRecording",
  components: {},
  data(){
    return {
      queryUri: [],
      postForm: {
        last_name: '',
        first_name: '',
        middle_name: '',
        birthday: '',
        city: '',
        email: '',
        phone: '',
      },
    }
  },
  computed: {
    validFields(){
      if(this.postForm.last_name === ''){
        return false
      }
      if(this.postForm.first_name === ''){
        return false
      }
      //if(this.postForm.middle_name === ''){
      //  return false
      //}
      if(this.postForm.birthday === ''){
        return false
      }
      if(this.postForm.city === ''){
        return false
      }
      if(this.postForm.email === ''){
        return false
      }
      if(this.postForm.phone === ''){
        return false
      }
      return true
    },
    requestData(){
      return this.$store.state.requestData
    },
  },
  created() {
    this.queryUri = new URLSearchParams(window.location.search);
    if(this.queryUri.has('email')){
      this.postForm.email = this.queryUri.get('email');
    }
    if(this.queryUri.has('phone')){
      this.postForm.phone = this.queryUri.get('phone');
    }
    if(this.queryUri.has('city')){
      this.postForm.city = this.queryUri.get('city');
    }
    if(this.queryUri.has('first_name')){
      this.postForm.first_name = this.queryUri.get('first_name');
    }
    if(this.queryUri.has('last_name')){
      this.postForm.last_name = this.queryUri.get('last_name');
    }
  },
  mounted() {},
  methods:{
    sendOrder(){
      let form = this.postForm
      let preSendForm = true
      let request = Object.assign({utm: {}}, this.requestData)
      // let keysForm = Object.keys(this.postForm)

      let utmList = [
        'utm_source',
        'utm_medium',
        'utm_campaign',
        'utm_content',
        'utm_term',
      ];
      let urlObject = {};
      let utmObj = {};
      try {
        urlObject = new URL(window.location.href);
        for (let utmItem of utmList) {
          utmObj[utmItem] = '';
          let val = urlObject.searchParams.get(utmItem);
          if (val) {
            request.utm[utmItem.toUpperCase()] = val
          }
        }
      } catch (e) {
        console.log('e: ', e);
      }
      for(let keyItem in form){
        request.user[keyItem] = form[keyItem];
      }

      this.$store.dispatch('setRequestData', request);

      if(preSendForm){
        this.$store.dispatch('setLoader', true);
        ordersApi.store(request).then(response => {
          const { payment_link } = response

          if (!payment_link) {
            // eslint-disable-next-line no-console
            console.log('Verification failed, please Login again.')
            this.$store.dispatch('setLoader', false);
          }
          else {
            this.$store.dispatch('setRequestData', requestData);
            this.$store.dispatch('setLoader', false);
            window.location.href = payment_link
          }
        }).catch(error => {
          this.$store.dispatch('setLoader', false);
          // eslint-disable-next-line no-console
          console.log(JSON.parse(JSON.stringify(error)))
        })
      }
      else{
        return false
      }
    },
  },
}
</script>

<style scoped lang="scss">

.recording{
  &--field{
    position: relative;
    width: 194px;
    margin: 25px auto 0;
    label{
      font-family: Arial, Helvetica, sans-serif;
      margin: 0 auto 5px;
      width: 194px;
      display: inline-block;
    }
    input{
      font-family: Arial, Helvetica, sans-serif;
      font-size: 18px;
      height: 40px;
      display: block;
      border-radius: 10px;
      border: 2px solid black;
      padding-left: 10px;
      width: 100%;
      box-sizing: border-box;
    }
    .phoneField{
      font-family: Arial, Helvetica, sans-serif;
      font-size: 18px;
      height: 40px;
      display: block;
      border-radius: 10px;
      border: 2px solid black;
      padding-left: 10px;
      width: 100%;
      box-sizing: border-box;
    }

    &.birthday{
      input{
        padding: 0 6px 0 12px;
      }
    }

    &.error{
      input{
        border: 2px solid red;
      }
    }
  }
  &__action{}
  &__btn{
    cursor: pointer;
    font-family: Arial, Helvetica, sans-serif;
    width: 190px;
    margin: 25px auto 0;
    font-size: 16px;
    display: block;
    line-height: 36px;
    text-align: center;
    border: double 2px transparent;
    border-radius: 13px;
    background-image: linear-gradient(white, white), radial-gradient(circle at top left, #E34396, #FF637C);
    background-origin: border-box;
    background-clip: content-box, border-box;

    &.no-active{
      cursor: not-allowed;
      opacity: 0.2;
      background-image: linear-gradient(white, white), radial-gradient(circle at top left, black, black);
      background-origin: border-box;
      background-clip: content-box, border-box;
    }
  }
}
</style>
