<template>
  <div class="calendar">
    <div class="calendar--month">
      <div class="calendar--month__list">
        <div class="calendar--month__item" :class="[currentMonth(item) ? 'current' : '']" v-for="item in monthList" :key="item" @click="changeMonth(item)">{{ monthName(item) }}</div>
      </div>
    </div>
    <div class="calendar--month__days">
      <div class="calendar--column">
        <b>ПН</b>
      </div>
      <div class="calendar--column">
        <b>ВТ</b>
      </div>
      <div class="calendar--column">
        <b>СР</b>
      </div>
      <div class="calendar--column">
        <b>ЧТ</b>
      </div>
      <div class="calendar--column">
        <b>ПТ</b>
      </div>
      <div class="calendar--column">
        <b>СБ</b>
      </div>
      <div class="calendar--column">
        <b>ВС</b>
      </div>
      <div class="calendar--day" v-for="(day, index) in days[monthSelect]" :style="{ gridColumn: column(index), backgroundColor: statusDate(day)}" :class="{ today: today(day) }" :key="day+index" @click="selectData(day)">
        <b>{{ day.format('D') }}</b>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'

export default {
  name: "LayoutCalendar",
  components: {},
  data() {
    return {
      monthList: [],
      monthSelect: moment().startOf('month').format('YYYY-MM-DD'),
      monthPrev: moment().startOf('month'),
      monthNext: moment().add(1, 'M').startOf('month'),
      days: []
    }
  },
  computed: {
    ...mapGetters(['events']),
    requestData(){
      return this.$store.state.requestData
    },
  },
  created() {
    let monthDate = moment().startOf('month')
    let monthDateNow = monthDate.format('YYYY-MM-DD')
    let nextMonthData = moment().add(1, 'M').startOf('month')
    let nextMonthDataNow = nextMonthData.format('YYYY-MM-DD')
    let monthData = {}
    monthData[monthDateNow] = [...Array(monthDate.daysInMonth())].map((_, i) => monthDate.clone().add(i, 'day'))
    monthData[nextMonthDataNow] = [...Array(nextMonthData.daysInMonth())].map((_, i) => nextMonthData.clone().add(i, 'day'))
    this.days = monthData;

    this.monthList.push(monthDate.format('MM'))
    this.monthList.push(nextMonthData.format('MM'))
    //this.days = [...Array(monthDate.daysInMonth())].map((_, i) => monthDate.clone().add(i, 'day'))
  },
  methods: {
    column(index) {
      if (index == 0) {
        if(this.days[this.monthSelect][0].day() === 0 && this.days[this.monthSelect][0].format('YYYY-MM-DD') === '2023-01-01'){
          return 7
        }
        return this.days[this.monthSelect][0].day()// + 1
      }
    },
    today(day) {
      return moment().isSame(day, 'day')
    },
    monthName(month) {
      let monthName = {
        '01': 'Январь',
        '02': 'Февраль',
        '03': 'Март',
        '04': 'Апрель',
        '05': 'Май',
        '06': 'Июнь',
        '07': 'Июль',
        '08': 'Август',
        '09': 'Сентябрь',
        '10': 'Октябрь',
        '11': 'Ноябрь',
        '12': 'Декабрь',
      }

      return monthName[month]
    },
    currentMonth(month){
      let date = new Date(this.monthSelect);
      let formatted = moment(date).format('MM');
      return formatted === month
    },
    changeMonth(month){
      this.monthSelect = this.monthPrev.format('MM') !== month ? this.monthNext.format('YYYY-MM-DD') : this.monthPrev.format('YYYY-MM-DD')
    },
    statusDate(day){
      let status = false
      let formattedDay = moment(day).format('YYYY-MM-DD')
      let filterEvents = this.events.filter(item => {
        return item.data === formattedDay && item.status
      })

      status = filterEvents.length > 0;

      return status ? '#008000' : '#8b0000'
    },
    checkStatusDate(day){
      let status = false
      let formattedDay = moment(day).format('YYYY-MM-DD')
      let filterEvents = this.events.filter(item => {
        return item.data === formattedDay && item.status
      })

      status = filterEvents.length > 0;

      return status
    },
    selectData(day){
      if(this.checkStatusDate(day)){
        let formattedDay = moment(day).format('YYYY-MM-DD')

        let request = Object.assign({}, this.requestData)
        request.user.date_recording = formattedDay;
        request.user.isRecording = true;

        this.$store.dispatch('setRequestData', request);
        this.$store.dispatch('setComp', 'calendar-time')
      }
    }
  },
}
</script>

<style scoped lang="scss">
.calendar{
  max-width: 500px;
  margin: 30px auto 0;
  padding: 0 20px;

  &--header{}
  &--column{
    font-family: Arial, Helvetica, sans-serif;
    height: 50px;
    text-align: center;
    background-color: black;
    color: white;
    margin: 0 1px;

    & > b{
      display: inline-block;
    }
  }
  &--list{}
  &--day{}
  &--month{
    &__list{}
    &__item{
      cursor: pointer;
      display: inline-block;
      margin-left: 10px;
      margin-top: 15px;
      padding-left: 5px;
      padding-right: 5px;
      padding-top: 2px;
      color: black;
      font-family: Arial, Helvetica, sans-serif;
      font-size: 18px;
      text-align: center;
      border: 2px solid black;
      border-radius: 5px;
      margin-bottom: 30px;

      &.current{
        color: #d81e85;
        border: 2px solid #d81e85;
      }
    }
    &__days{
      display: grid;
      grid-template-columns: repeat(7, 1fr);
      width: 100%;

      .success{
        background-color: #008000;
      }
      .failed{
        background-color: #8b0000;
      }
      & > *{
        align-items: center;
        display: flex;
        justify-content: center;

        font-family: Arial, Helvetica, sans-serif;
        height: 50px;
        text-align: center;
        background-color: black;
        color: white;
        margin: 1px 1px 0;

        & > b{
          display: inline-block;
        }
      }
      & > *::before {
        content: "";
        display: inline-block;
        height: 0;
        padding-bottom: 100%;
        width: 1px;
      }
    }
  }
}
</style>
