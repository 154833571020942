const axios = require('axios')

 //  window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
 // window.axios.defaults.withCredentials = true

// Create axios instance
const service = axios.create({
  baseURL: 'https://appointment.arcana.taroirena.ru/api/',
  // timeout: 10000, // Request timeout
  headers: { 'X-Requested-With': 'XMLHttpRequest' },
  withCredentials: true,
})

// // Request intercepter
service.interceptors.request.use(
   config => config,
   error => {
     console.log(error) // for debug
     Promise.reject(error)
   }
)
//
// // response pre-processing
 service.interceptors.response.use(
   response => response.data,
   error => {
     console.log(error) // for debug
     return Promise.reject(error)
   }
 )

export default service
