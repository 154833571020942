<template>
  <div class="services">
    <div class="services--list">
      <div class="services--item d-flex align-items-center" v-for="service in servicesList" :key="'service_' + service.id" @click="setService(service.id)">
        <div class="services--icon" :class="serviceID === service.id ? 'selected' : ''"></div>
        <div class="services--name">{{ service.name }} - {{ service.price }} ₽</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LayoutServices",
  computed: {
    services() {
      return this.$store.state.services
    },
    servicesList() {
      let services = this.services
      let expertID = this.$store.state.requestData.data.expert_id
      return services.filter(item => {
        return item.expert.id === expertID
      })
    },
    serviceID(){
      return this.$store.state.requestData.data.product_id
    },
    requestData(){
      return this.$store.state.requestData
    },
  },
  methods: {
    setService(id){
      let request = Object.assign({}, this.requestData)
      request.data.product_id = id;

      this.$store.dispatch('setRequestData', request);
      this.$store.dispatch('setComp', 'start')
    }
  }
}
</script>

<style scoped lang="scss">
.services {
  &--list {}

  &--item {
    cursor: pointer;
    padding-top: 30px;
    padding-bottom: 30px;
    width: 90%;
    max-width: 500px;
    margin: 0 auto;
    border-bottom: 2px solid #e0e0e0;
  }

  &--icon {
    content: '';
    background-image: url('../assets/radio.svg');
    height: 20px;
    width: 20px;
    background-repeat: no-repeat;
    background-position: left 0;
    margin-right: 5px;

    &.selected {
      background-image: url('../assets/c_radio.svg');
    }
  }

  &--name {
    font-size: 18px;
    line-height: 20px;
    margin-left: 5px;
    font-family: Arial, Helvetica, sans-serif;
  }
}
</style>