<template>
  <div class="experts">
    <div class="experts--list">
      <div class="experts--item d-flex align-items-center" v-for="expert in experts" :key="'expert_' + expert.id" @click="setExpert(expert.id)">
        <div class="experts--icon" :class="expertID === expert.id ? 'selected' : ''"></div>
        <div class="experts--name">{{ expert.user.name }} {{ expert.user.last_name }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LayoutExperts",
  computed: {
    experts() {
      return this.$store.state.experts
    },
    expertID(){
      return this.$store.state.requestData.data.expert_id
    },
    requestData(){
      return this.$store.state.requestData
    },
  },
  methods: {
    setExpert(id){
      let request = Object.assign({}, this.requestData)
      request.data.expert_id = id;
      request.data.product_id = false;

      this.$store.dispatch('setRequestData', request);
      this.$store.dispatch('setComp', 'start')
    }
  }
}
</script>

<style scoped lang="scss">
.experts {
  &--list {}

  &--item {
    cursor: pointer;
    padding-top: 30px;
    padding-bottom: 30px;
    width: 90%;
    max-width: 500px;
    margin: 0 auto;
    border-bottom: 2px solid #e0e0e0;
  }

  &--icon {
    content: '';
    background-image: url('../assets/radio.svg');
    height: 20px;
    width: 20px;
    background-repeat: no-repeat;
    background-position: left 0;
    margin-right: 5px;

    &.selected{
      background-image: url('../assets/c_radio.svg');
    }
  }

  &--name {
    font-size: 18px;
    line-height: 20px;
    margin-left: 5px;
    font-family: Arial, Helvetica, sans-serif;
  }
}
</style>
