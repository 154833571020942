<template>
  <div class="events">
    <div class="events--list">
      <div class="events--item d-flex align-items-center" v-for="event in events" :key="'expert_' + event.id" @click="setEventTime(event)">
        <div class="events--icon" :class="eventID === event.title ? 'selected' : ''"></div>
        <div class="events--name">{{ event.title }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LayoutCalendarTime",
  computed: {
    events() {
      let events = this.$store.getters.events
      let requestData = this.requestData
      let filterEvents = events.filter(item => {
        return item.data === requestData.user.date_recording && item.status
      })

      return filterEvents
    },
    eventID(){
      let dateSplit = this.$store.state.requestData.user.date_recording.split(' ')
      return dateSplit[1]
    },
    requestData(){
      return this.$store.state.requestData
    },
  },
  methods: {
    setEventTime(event){
      let request = Object.assign({}, this.requestData)
      request.data.event_id = event.id;
      request.user.date_recording = request.user.date_recording + ' ' + event.title;

      this.$store.dispatch('setRequestData', request);
      this.$store.dispatch('setComp', 'start')
    }
  }
}
</script>

<style scoped lang="scss">
.events {
  &--list {}

  &--item {
    cursor: pointer;
    padding-top: 30px;
    padding-bottom: 30px;
    width: 90%;
    max-width: 500px;
    margin: 0 auto;
    border-bottom: 2px solid #e0e0e0;
  }

  &--icon {
    content: '';
    background-image: url('../assets/radio.svg');
    height: 20px;
    width: 20px;
    background-repeat: no-repeat;
    background-position: left 0;
    margin-right: 5px;

    &.selected{
      background-image: url('../assets/c_radio.svg');
    }
  }

  &--name {
    font-size: 18px;
    line-height: 20px;
    margin-left: 5px;
    font-family: Arial, Helvetica, sans-serif;
  }
}
</style>
