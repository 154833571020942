<template>
  <div class="layout-start">
    <div class="layout-start__expert" @click="showLayout('experts', true)">
      <div class="layout-start__expert--label label-name">Эксперт</div>
      <div class="layout-start__expert--name">{{ expert.user.name }} {{ expert.user.last_name }}</div>
    </div>
    <div class="layout-start__service" :class="[expert.id ? '' : 'no-active']" @click="showLayout('services', !!expert.id)">
      <div class="layout-start__service--label label-name">Услуга</div>
      <div class="layout-start__service--name" v-if="service.id">{{ service.name_kkm }} - {{ service.price }} ₽</div>
      <div class="layout-start__service--name" v-else></div>
    </div>
    <div class="layout-start__event" :class="[service.id ? '' : 'no-active', showCalendar ? '' : 'not-req']" @click="showLayout('calendar', !!service.id)">
      <div class="layout-start__event--label label-name">Дата и время</div>
      <div class="layout-start__event--name">{{ viewData(service.id) }}</div>
    </div>
    <div class="layout-start__action">
      <div class="layout-start__btn " :class="{'no-active': !activeRecordBtn}" @click="showLayout('recording', activeRecordBtn)">Записаться</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LayoutStart",
  data(){
    return {
      showCalendar: true,
    }
  },
  computed:{
    experts() {
      return this.$store.state.experts
    },
    services() {
      return this.$store.state.services
    },
    expert(){
      let experts = this.experts
      let expertID = this.$store.state.requestData.data.expert_id
      let expertData = {
        id: null,
        user: {
          name: '',
          last_name: ''
        }
      }
      if(expertID){
        for(let key in experts){
          if(experts[key].id === expertID){
            expertData = experts[key];
          }
        }
        return expertData
      }
      else{
        return expertData
      }
    },
    service(){
      let services = this.services
      let serviceID = this.$store.state.requestData.data.product_id
      let serviceData = {
        id: null,
        name: '',
        price: '',
        time_slot: '',
      }
      if(serviceID){
        for(let key in services){
          if(services[key].id === serviceID){
            serviceData = services[key];
          }
        }
        return serviceData
      }
      else{
        return serviceData
      }
    },
    activeRecordBtn(){
      if(this.$store.state.requestData.data.product_id){
        if(this.service.time_slot){
          if(this.$store.state.requestData.user.date_recording){
            let dataSplit = this.$store.state.requestData.user.date_recording.split(' ')
            return dataSplit.length > 1
          }
          else{
            return false
          }
        }
        else{
          return true
        }
      }
      else{
        return false
      }
    }
  },
  methods:{
    showLayout(layout, show){
      if(layout === 'calendar' && !this.showCalendar){
        return false
      }
      if(show){
        this.$store.dispatch('setComp', layout)
      }
    },
    viewData(){
      if(this.$store.state.requestData.data.product_id){
        if(this.service.time_slot){
          if(this.$store.state.requestData.user.date_recording){
            let dataSplit = this.$store.state.requestData.user.date_recording.split(' ')
            let dataParse = dataSplit[0].split('-')
            if(dataSplit.length > 1){
              return dataParse[2] + '-' + dataParse[1] + '-' + dataParse[0] + ' ' + dataSplit[1]
            }
            else{
              return dataParse[2] + '-' + dataParse[1] + '-' + dataParse[0]
            }
          }
          else{
            return ''
          }
        }
        else{
          this.showCalendar = false
          return 'Для этой услуги не нужно выбирать время!'
        }
      }
      else{
        return ''
      }
    },
  }
}
</script>

<style scoped lang="scss">
.layout-start {
  &__expert {
    cursor: pointer;
    padding-top: 30px;
    padding-bottom: 30px;
    width: calc(100% - 10%);
    max-width: 500px;
    margin: 0 auto;
    border-bottom: 2px solid #e0e0e0;

    &--label {
      font-family: Arial, Helvetica, sans-serif;
      font-size: 18px;
      display: flex;

      &::before {
        display: inline-block;
        content: '';
        width: 20px;
        height: 20px;
        margin-right: 10px;
        background-image: url('../assets/account.svg');
      }
    }

    &--name {
      position: absolute;
      margin-top: 5px;
      font-family: Arial, Helvetica, sans-serif;
      font-size: 15px;
      color: #d81e85;
    }
  }

  &__service {
    cursor: pointer;
    padding-top: 30px;
    padding-bottom: 30px;
    width: calc(100% - 10%);
    max-width: 500px;
    margin: 0 auto;
    border-bottom: 2px solid #e0e0e0;

    &.no-active{
      cursor: not-allowed;
      color: #cccccc;

      & > .label-name{
        &::before{
          opacity: 0.2;
        }
      }
    }

    &--label {
      font-family: Arial, Helvetica, sans-serif;
      font-size: 18px;
      display: flex;

      &::before {
        display: inline-block;
        content: '';
        width: 20px;
        height: 20px;
        margin-right: 10px;
        background-image: url('../assets/bulb.svg');
      }
    }

    &--name {
      position: absolute;
      margin-top: 5px;
      font-family: Arial, Helvetica, sans-serif;
      font-size: 15px;
      color: #d81e85;
    }
  }

  &__event {
    cursor: pointer;
    padding-top: 30px;
    padding-bottom: 30px;
    width: calc(100% - 10%);
    max-width: 500px;
    margin: 0 auto;
    border-bottom: 2px solid #e0e0e0;

    &.not-req{
      cursor: not-allowed;
    }
    &.no-active{
      cursor: not-allowed;
      color: #cccccc;

      & > .label-name{
        &::before{
          opacity: 0.2;
        }
      }
    }
    &--label {
      font-family: Arial, Helvetica, sans-serif;
      font-size: 18px;
      display: flex;

      &::before {
        display: inline-block;
        content: '';
        width: 20px;
        height: 20px;
        margin-right: 10px;
        background-image: url('../assets/calendar.svg');
      }
    }

    &--name {
      position: absolute;
      margin-top: 5px;
      font-family: Arial, Helvetica, sans-serif;
      font-size: 15px;
      color: #d81e85;
    }
  }

  &__action{}
  &__btn{
    cursor: pointer;
    font-family: Arial, Helvetica, sans-serif;
    width: 190px;
    margin: 0 auto;
    margin-top: 34px;
    font-size: 16px;
    display: block;
    line-height: 36px;
    text-align: center;
    border: double 2px transparent;
    border-radius: 13px;
    background-image: linear-gradient(white, white), radial-gradient(circle at top left, #E34396, #FF637C);
    background-origin: border-box;
    background-clip: content-box, border-box;

    &.no-active{
      cursor: not-allowed;
      opacity: 0.2;
      background-image: linear-gradient(white, white), radial-gradient(circle at top left, black, black);
      background-origin: border-box;
      background-clip: content-box, border-box;
    }
  }
}
</style>
