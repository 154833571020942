<template>
  <div class="site--wrapper">
    <header class="site--header d-flex align-items-center justify-content-center" id="header">
      <div class="back_btn" v-if="showBack" @click="getBack()">Назад</div>
      <div class="site--header__title">Онлайн-запись</div>
    </header>
    <main class="site--content" role="main" id="content">
      <form>
        <component :is="compName"></component>
      </form>
    </main>
    <loading
        :active.sync="isLoading"
        :loader="loader.loader"
        :background-color="loader.bcolor"
        :color="loader.fcolor"
        :height="loader.heightLoader"
        :width="loader.widthLoader"
        :is-full-page="loader.fullPage"
    ></loading>
  </div>
</template>

<script>
import LayoutStart from './components/LayoutStart'
import LayoutExperts from './components/LayoutExperts'
import LayoutServices from './components/LayoutServices'
import LayoutCalendar from './components/LayoutCalendar'
import LayoutCalendarTime from './components/LayoutCalendarTime'
import LayoutRecording from './components/LayoutRecording'

export default {
  name: 'App',
  components: {
    LayoutStart,
    LayoutExperts,
    LayoutServices,
    LayoutCalendar,
    LayoutCalendarTime,
    LayoutRecording,
  },
  data(){
    return {}
  },
  computed:{
    loader(){
      return this.$store.state.loader;
    },
    isLoading(){
      return this.$store.state.isLoading;
    },
    compName(){
      return 'layout-' + this.$store.state.compName
    },
    showBack(){
      return this.compName !== 'layout-start'
    }
  },
  async created() {
    await this.$store.dispatch('setExperts')
    await this.$store.dispatch('setServices')
    await this.$store.dispatch('setEvents')
  },
  methods:{
    getBack(){
      switch (this.$store.state.compName){
        case 'calendar-time':
          this.showLayout('calendar');
          break;
        default:
          this.showLayout('start');
          break;
      }
    },
    showLayout(layout){
      this.$store.dispatch('setComp', layout)
    }
  }
}
</script>

<style lang="scss">
@import "~bootstrap/scss/bootstrap-grid";

*{
  margin: 0;
  outline: none !important;
}
.site{
  &--wrapper{}
  &--header{
    position: relative;
    background-color: black;
    background-image: url('assets/krug.png');
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: 100px 50%;
    width: 100%;
    height: 100px;

    .back_btn{
      cursor: pointer;
      position: absolute;
      top: 50%;
      left: 30px;
      padding: 2px 5px;
      color: white;
      font-family: Arial, Helvetica, sans-serif;
      font-size: 16px;
      display: block;
      text-align: center;
      border: 2px solid white;
      border-radius: 5px;

      transform: translateY(-50%);
      -webkit-transform: translateY(-50%);
    }
    &__title{
      text-align: center;
      color: white;
      font-family: Arial, Helvetica, sans-serif;
      font-weight: bold;
      font-size: 18px;
    }
  }
}
.flagPhone{
  position: absolute;
  top: 4px;
  right: 6px;
}
.phoneField{
  font-family: Arial, Helvetica, sans-serif;
  font-size: 18px;
  height: 40px;
  display: block;
  border-radius: 10px;
  border: 2px solid black;
  padding-left: 10px;
  width: 100%;
  box-sizing: border-box;
}
</style>
