import request from '../utils/request'

/**
 * Simple RESTful resource class
 */
class Resource {
  constructor(uri) {
    this.uri = uri
  }
  list(query) {
    return request({
      url: '/' + this.uri,
      method: 'get',
      params: query,
    })
  }
  checkAuth() {
    return request({
      url: '/' + this.uri + '/check',
      method: 'get',
    })
  }
  logout() {
    return request({
      url: '/' + this.uri + '/logout',
      method: 'post',
    })
  }
  get(id) {
    return request({
      url: '/' + this.uri + '/' + id,
      method: 'get',
    })
  }
  store(resource) {
    return request({
      url: '/' + this.uri,
      method: 'post',
      data: resource,
    })
  }
  massDelete(resource) {
    return request({
      url: '/' + this.uri + '/delete',
      method: 'post',
      data: resource,
    })
  }
  update(id, resource) {
    return request({
      url: '/' + this.uri + '/' + id,
      method: 'put',
      data: resource,
    })
  }
  destroy(id) {
    return request({
      url: '/' + this.uri + '/' + id,
      method: 'delete',
    })
  }
  getEventList(resource) {
    return request({
      url: '/' + this.uri + '/for-client',
      method: 'post',
      data: resource,
    })
  }
  getExperts(resource) {
    return request({
      url: '/' + this.uri + '/get-experts',
      method: 'post',
      data: resource,
    })
  }
}

export { Resource as default }
